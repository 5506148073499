// custom typefaces
import "typeface-montserrat";
import "typeface-merriweather";

import "jquery/dist/jquery.min.js";
import "popper.js/dist/popper.min";
import "bootstrap/dist/js/bootstrap.min.js";

import React from "react";
import { SourceProvider } from "./src/contexts";

export const wrapRootElement = ({ element }) => (
  <SourceProvider>{element}</SourceProvider>
);
